import React, { useState } from "react";
import "./Pages.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RegisterInput from "../components/register-components/RegisterInput";

const Register = () => {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({
        user_id: "",
        password: "",
        password_check: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        axios.post(`${process.env.REACT_APP_API}/register`, userData)
        .then(() => navigate("/"))
    }
    const handleChange = (e) => {
        const { name, value } = e.target

        setUserData({
            ...userData,
            [name]: value
        })
    }

    return (
        <div className="register" style={{ "marginTop" : "40px" }}>
            <h1>회원가입</h1>
            <h3>현재 이 기능은 구현되지 않았습니다</h3>
            <RegisterInput
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                userData={userData}
            />
        </div>
    )
}

export default Register