import React from "react";
import "./Shop.css"

const ProductCard = () => {
    return (
        <div className="product-card">
            <div className="image-wrapper">
                
            </div>
            <div className="info">
                <div className="name">제품명</div>
                <div className="price">가격</div>
            </div>
        </div>
    )
}

export default ProductCard