import React from "react";
import "./Register.css"

const RegisterInput = ({ handleSubmit, handleChange, userData }) => {
    return (
        <form className="register-input" onSubmit={ handleSubmit }>
            <input 
                type="text" 
                placeholder="아이디"
                value={ userData.user_id }
                onChange={ handleChange }
                required
            />
            <input 
                type="password" 
                placeholder="비밀번호"
                value={ userData.password }
                onChange={ handleChange }
                required
            />
            <input 
                type="password" 
                placeholder="비밀번호 확인"
                value={ userData.password_check }
                onChange={ handleChange }
                required
            />
            <button type="submit">회원가입</button>
        </form>
    )
}

export default RegisterInput