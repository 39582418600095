import React from "react";
import "./Estimate.css"
import FilterLayout from "./FilterLayout";

const overlap_filters = ["가격", "브랜드"]
const wheelset_filters = [...overlap_filters, "림 높이", "프론트 리어 구분"]
const frame_filters = [...overlap_filters, "소재"]
const seatpost_filters = [...overlap_filters, "사이즈", "길이"]
const saddle_filters = [...overlap_filters]
const stem_filters = [...overlap_filters, "사이즈", "길이"]
const handlebar_filters = [...overlap_filters, "사이즈", "타입", "길이"]
const chainring_filters = [...overlap_filters, "사이즈", "이빨 수"]
const crank_filters = [...overlap_filters, "사이즈"]

const Filter = ({ partSelect, isDivide, getIsDivide }) => {
    const getFilters = () => {
        if (partSelect === "wheelset" || partSelect === "front_wheel" || partSelect === "rear_wheel") {
            return wheelset_filters
        } else if (partSelect === "frame") {
            return frame_filters
        } else if (partSelect === "seatpost") {
            return seatpost_filters
        } else if (partSelect === "saddle") {
            return saddle_filters
        } else if (partSelect === "stem") {
            return stem_filters
        } else if (partSelect === "handlebar") {
            return handlebar_filters
        } else if (partSelect === "chainring") {
            return chainring_filters
        } else if (partSelect === "crank") {
            return crank_filters
        } else {
            return []
        }
    }

    return (
        <div className="filter">
            <div className="filter-title">
                필터
            </div>
            <div className="filter-body">
                {
                    getFilters().map((filter_type, i) => {
                        return(
                            <FilterLayout
                                key={ i }
                                filter_type={ filter_type }
                                isDivide={ isDivide }
                                getIsDivide={ getIsDivide }
                            />
                        )
                    }) 
                }
            </div>
        </div>
    )
    
}

export default Filter