import React from "react";
import "./Home.css"
import { Link } from "react-router-dom"
import HomeProductCard from "./HomeProductCard"
import ProductMissing from "../ProductMissing";

const HomeProductPreview = ({ category }) => {
    const products = []
    let link = ""
    switch (category) {
        case "완성차":
            link = "/complete"
            break
        case "파츠":
            link = "/part"
            break
        case "액세서리":
            link = "/accessorie"
            break
        case "기타":
            link = "/etc"
            break
        default:
            link = "/"
    }

    return (
        <div className="home-product-preview">
            <Link className="category-link" to={ link }>{ category }&gt;</Link>
            <div className="product-stack">
                {products.length
                ?
                products.map((product, i) => {
                    return (
                        <HomeProductCard key={i}/>
                    )
                })
                :
                <ProductMissing/>
                }
            </div>
        </div>
    )
}

export default HomeProductPreview