import React from "react";
import "./Home.css"
import { useNavigate } from "react-router-dom"

const MainBanner = () => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/estimate")
    }

    return (
        <div className="main-banner">
            <div className="main-banner-content">
                <h1>당신의 자전거를 만들어보세요</h1>
                <button onClick={ handleClick }>견적 맞추기</button>
            </div>
        </div>
    )
}

export default MainBanner