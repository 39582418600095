import React, { useState, useEffect } from "react";
import "./Components.css"
import { Link } from "react-router-dom";
import axios from "axios";

const Header = ({ isMobile }) => {
    const [isLogin, setIsLogin] = useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/login`, { withCredentials: true })
        .then(() => setIsLogin(true))
        .catch(() => setIsLogin(false))
    }, [])

    const logoutUser = () => {
        axios.get(`${process.env.REACT_APP_API}/logout`, { withCredentials: true })
        .then(() => setIsLogin(false))
    }

    return (
        <div className="header">
            <div className="login-menu-wrapper">
                <div className="login-menu">
                    {
                        isLogin
                        ? <div onClick={ logoutUser }>로그아웃</div>
                        : <Link to="/login">로그인</Link>
                    }
                    <Link to="/register">회원가입</Link>
                </div>
            </div>
            <div className="logo">
                <Link to="/">
                    <img alt="logo" src={ isMobile ? `${process.env.PUBLIC_URL}/BiF_mobile_logo.png` : `${process.env.PUBLIC_URL}/BiF_browser_logo.png` }/>
                </Link>
            </div>
            <div className="menu-wrapper">
                <div className="menu">
                    <Link to="/complete">완성차</Link>
                    <Link to="/part">파츠</Link>
                    <Link to="/accessorie">액세서리</Link>
                    <Link to="/etc">기타</Link>
                    <Link to="/estimate">견적</Link>
                    {
                        isLogin
                        ? <Link to="/pdm">PDM</Link>
                        : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header