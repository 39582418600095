import React from "react";
import "./Estimate.css"
import FilterConfig from "./FilterConfig";

const FilterLayout = ({ filter_type, isDivide, getIsDivide }) => {
    return (
        <div className="filter-layout">
            <div className="filter-type">
                { filter_type }
            </div>
            <div className="filter-config">
                <FilterConfig 
                    filter_type={ filter_type } 
                    isDivide={ isDivide }
                    getIsDivide={ getIsDivide }
                />
            </div>
        </div>
    )
}

export default FilterLayout