import React, { useEffect, useState } from "react";
import ProductInputForm from "../components/pdm-components/ProductInputForm";
import AllProductList from "../components/pdm-components/AllProductList";
import axios from "axios";
import { useNavigate } from "react-router-dom"

const PartDataManager = () => {
  const [formData, setFormData] = useState({
    part: "",
    img_src: "",
    product_name: "",
    simple_explain: "",
    product_price: "",
    brand: "",
    size: "",
    length: "",
    rim_height: "",
    material: "",
    type: "",
    t_num: "",
    seatpost_position: "",
    stem_Xposition: "",
    stem_Yposition: ""
  });
  const [isUpdate, setIsUpdate] = useState("")
  const navigate = useNavigate()
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/login`, { withCredentials: true })
    .catch(() => navigate("/"))
  }, [])

  const selectFormData = (res) => {
    // console.log(res.data)
    setIsUpdate(res.data._id)

    setFormData({
      ...res.data
    })
  }
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    
    // If input type is number, parse it to a number
    const valueToStore = type === "number" ? parseFloat(value) || 0 : value;

    setFormData({
      ...formData,
      [name]: valueToStore,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const requestData = {
      ...formData,
      size: formData.size || null,
      length: formData.length || null,
      rim_height: formData.rim_height || null,
      material: formData.material || null,
      type: formData.type || null,
      t_num: formData.t_num || null,
      seatpost_position: formData.seatpost_position || null,
      stem_Xposition: formData.stem_Xposition || null,
      stem_Yposition: formData.stem_Yposition || null
    };

    fetch(`${process.env.REACT_APP_API_PARTS}`, {
      method: `${isUpdate ? "PUT" : "POST"}`,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setIsUpdate("")
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div style={{ width: "1000px", marginTop: "40px", display: "flex", justifyContent: "space-between" }}>
      <ProductInputForm
        handleChange={ handleChange }
        handleSubmit={ handleSubmit }
        formData={ formData }
      />
      <AllProductList
        selectFormData={ selectFormData }
      />
    </div>
  );
};

export default PartDataManager;