import React, { useEffect } from "react";
import "./Estimate.css"
import PurchaseListLayout from "./PurchaseListLayout";

const PurchaseList = ({ selectedList, getCancelSelect, isMobile, isDivide }) => {
    const temp = { ...selectedList }
    if (!isDivide) {
        delete temp["rear_wheel"]
    }

    const postCancelSelect = (partSelect) => {
        getCancelSelect(partSelect)
    }
    
    useEffect(() => {
        if (isDivide === false) getCancelSelect("wheelset")
    }, [isDivide])

    return (
        <div className="purchase-list">
            <div className="purchase-list-title">
                커스텀목록
            </div>
            <div className="purchase-list-body">
                {
                    Object.entries(temp).map(([part_kind, product_info], i) => {
                        return (
                            <PurchaseListLayout
                                key={ i }
                                part_kind={ part_kind }
                                product_info={ product_info }
                                postCancelSelect={ postCancelSelect }
                                isDivide={ isDivide }
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PurchaseList