import React from "react";
import "./Home.css"

const HomeProductCard = () => {
    return (
        <div className="home-product-card">
            
        </div>
    )
}

export default HomeProductCard