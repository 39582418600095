import React from "react";
import "./Product.css"

const ProductInterface = () => {
    return (
        <div className="product-interface">
            <div className="info">
                <h1 className="name">
                    제품명
                </h1>
                <h3 className="price">
                    가격
                </h3>
            </div>
            <div className="interface">
                <div className="amount">
                    <h3 className="title">
                        수량
                    </h3>
                    <input type="number"/>
                </div>
                <button className="purchase">
                    구매하기
                </button>
            </div>
        </div>
    )
}

export default ProductInterface