import React from "react";
import { debounce } from "lodash";

const SearchBar = ({ setSearchKeyword }) => {
    const onChange = debounce((e) => {
        setSearchKeyword(e.target.value)
    }, 300)

    return (
        <div className="product-search">
            <input type="text" placeholder="검색어를 입력해주세요" onChange={ onChange }/>
        </div>
    )
}

export default SearchBar