import React from "react";
import "./Estimate.css"
import "./BikeResult.css"

const BikeResult = ({ selectedList, getPartSelect, isDivide }) => {
    const a = selectedList
    
    const postPartSelect = (part) => {
        getPartSelect(part)
    }

    const share = () => {
        let share_data = []
        for (const part in a) share_data.push(a[part].id)
        const encode_data = btoa(JSON.stringify(share_data))

        navigator.clipboard.writeText(`https://www.bikeisfashion.shop/?custom=${encode_data}`)
        alert("링크 복사 완료!")
    }

    return (
        <div className="bike-result-wrapper">
            <div className="bike-result">
                {
                    isDivide
                    ?   
                    <div className="wheelset">
                        <div id={ `${ a.rear_wheel.img_src && "set" }` } className="rear-wheel" onClick={ () => postPartSelect("rear_wheel") }>
                            <img src={ a.rear_wheel.img_src }/>
                        </div>
                        <div id={ `${ a.front_wheel.img_src && "set" }` } className="front-wheel" onClick={ () => postPartSelect("front_wheel") }>
                            <img src={ a.front_wheel.img_src }/>
                        </div>
                    </div>
                    :
                    <div className="wheelset" onClick={ () => postPartSelect("wheelset") }>
                        <div id={ `${ a.rear_wheel.img_src && "set" }` } className="rear-wheel">
                            <img src={ a.rear_wheel.img_src }/>
                        </div>
                        <div id={ `${ a.front_wheel.img_src && "set" }` } className="front-wheel">
                            <img src={ a.front_wheel.img_src }/>
                        </div>
                    </div>
                }
                <div id={ `${ a.frame.img_src && "set" }` } className="frame" onClick={ () => postPartSelect("frame") }>
                    <img src={ a.frame.img_src }/>
                </div>
                <div id={ `${ a.seatpost.img_src && "set" }` } className="seatpost" style={{ left: `${a.frame.seatpost_position}%` }} onClick={ () => postPartSelect("seatpost") }>
                    <img src={ a.seatpost.img_src }/>
                </div>
                <div id={ `${ a.saddle.img_src && "set" }` } className="saddle" style={{ left: `${a.frame.seatpost_position - 5}%` }}  onClick={ () => postPartSelect("saddle") }>
                    <img src={ a.saddle.img_src }/>
                </div>
                <div id={ `${ a.stem.img_src && "set" }` } className="stem" style={{ left: `${a.frame.stem_Xposition}%`, top: `${a.frame.stem_Yposition}%` }} onClick={ () => postPartSelect("stem") }>
                    <img src={ a.stem.img_src }/>
                </div>
                <div id={ `${ a.handlebar.img_src && "set" }` } className="handlebar" style={{ left: `${a.frame.stem_Xposition + 7}%`, top: `${a.frame.stem_Yposition}%` }} onClick={ () => postPartSelect("handlebar") }>
                    <img src={ a.handlebar.img_src }/>
                </div>
                <div className="chain">
                    {
                        a.chainring.img_src
                        ? <img src="https://i.postimg.cc/J7TMdC7M/10-20240830144632.png"/>
                        : <></>
                    }
                </div>
                <div id={ `${ a.chainring.img_src && "set" }` } className="chainring" onClick={ () => postPartSelect("chainring") }>
                    <img src={ a.chainring.img_src } style={{ width: "75.833%", height: "75.833%", visibility: a.chainring.img_src ? "visible" : "hidden" }}/>
                </div>
                <div id={ `${ a.crank.img_src && "set" }` } className="crank" onClick={ () => postPartSelect("crank") }>
                    <img src={ a.crank.img_src }/>
                </div>
                <button style={{ position: "absolute", left: "0%" }} onClick={ share }>공유하기</button>
            </div>
        </div>
    )
}

export default BikeResult