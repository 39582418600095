import React from "react";
import "./Pages.css"
import ProductInterface from "../components/product-components/ProductInterface";

const Product = ({ isMobile }) => {
    return (
        <div className="product">
            <div className="main">
                <div className="image-wrapper">
                    
                </div>
                {isMobile
                ?
                <></>
                :
                <ProductInterface/>
                }
            </div>
            <div className="explain">
                설명
            </div>
            {isMobile
            ?
            <ProductInterface/>
            :
            <></>
            }
        </div>
    )
}

export default Product