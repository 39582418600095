import './App.css';
import Header from "./components/Header"
import Footer from "./components/Footer"
import Estimate from './pages/Estimate';
import GoogleAdsense from './components/GoogleAdsense';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PartDataManager from './pages/PartDataManager';
import Login from "./pages/Login"
import Register from './pages/Register';
import Shop from './pages/Shop'
import Home from './pages/Home';
import { useState,useEffect } from 'react';
import { debounce } from 'lodash';
import Product from './pages/Product';

function App() {
  // mobile & browser design divide
  const [width, setWidth] = useState(window.innerWidth);

  // browser width event
  const isMobile = width < 768;
  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
  }, 200);

  // mobile & browser divide event
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); // cleanup
    };
  }, []);

  return (
    <div className="container">
      <BrowserRouter>
        <Header isMobile={ isMobile }/>
        {/* <GoogleAdsense /> */}
        <div className="main-content">
          <Routes>
            <Route path='/' element={ <Home isMobile={ isMobile }/> }/>
            <Route path='/complete' element={ <Shop isMobile={ isMobile }/> }/>
            <Route path='/part' element={ <Shop isMobile={ isMobile }/> }/>
            <Route path='/accessorie' element={ <Shop isMobile={ isMobile }/> }/>
            <Route path='/etc' element={ <Shop isMobile={ isMobile }/> }/>
            <Route path="/estimate" element={ <Estimate isMobile={ isMobile }/> }/>
            <Route path="/pdm" element={ <PartDataManager/> }/>
            <Route path="/login" element={ <Login/> }/>
            <Route path='/register' element={ <Register/> }/>
            <Route path='/products/:id' element={ <Product isMobile={ isMobile }/> }/>
          </Routes>
        </div>
        {
          // isMobile
          // ? <></>
          // : <GoogleAdsense />
        }
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
