import React from "react";
import "./Estimate.css"

const FilterConfig = ({ filter_type, isDivide, getIsDivide }) => {

    if (filter_type === "가격") {
        return (
            <div className="filter-price">
                <input type="number" className="min-price"/> ~ <input type="number" className="max-price"/>
            </div>
        )
    } else if (filter_type === "프론트 리어 구분") {
        return (
            <div>
                <input type="checkbox" onChange={ getIsDivide } checked={ isDivide }/>
            </div>
        )
    } else {
        return (
            <div>
                <input type="checkbox" />
            </div>
        )
    }
}

export default FilterConfig