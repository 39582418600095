import React from "react";
import MainBanner from "../components/home-components/MainBanner";
import "./Pages.css"
import HomeProductPreview from "../components/home-components/HomeProductPreview";

const Home = ({ isMobile }) => {
    const categories = ["완성차", "파츠", "액세서리", "기타"]

    return (
        <div className="home">
            <MainBanner/>
            <div className="home-product-content">
                {categories.map((category, i) => {
                    return (
                        <HomeProductPreview
                            key={i}
                            category={ category }
                            isMobile={ isMobile }
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default Home