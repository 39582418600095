import React from "react";
import "./Shop.css"
import ProductCard from "./ProductCard";
import ProductMissing from "../ProductMissing";

const ShopProductList = ({ isMobile }) => {
    const products = []
    let chunk = 4
    if (isMobile) { chunk = 2 } else { chunk = 4 }
    const splitData = data => {
        let split_data = []
        for (let i = 0; i < data.length; i += chunk) {
            split_data.push(data.slice(i, i + chunk))
        }
        return split_data
    }

    return (
        <div className="shop-product-list">
            {products.length
            ? 
            splitData(products).map((row, i) => {
                return (
                    <div className="row" key={i}>
                        {row.map((column, i) => {
                            return (
                                <ProductCard key={i}/>
                            )
                        })}
                    </div>
                )
            })
            :
            <ProductMissing/>
            }
        </div>
    )
}

export default ShopProductList