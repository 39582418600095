import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginInput from "../components/login-components/LoginInput";
import "./Pages.css"

const Login = () => {
    const navigate = useNavigate()
    const [loginData, setLoginData] = useState({
        user_id: "",
        password: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        axios.post(`${process.env.REACT_APP_API}/login`, 
            loginData,
            { withCredentials: true }
        )
        .then(() => navigate("/"))
        .then(() => navigate(0))
        .catch(() => alert("아이디 또는 비밀번호가 일치하지 않습니다"))
    }
    const handleChange = (e) => {
        const { name, value } = e.target

        setLoginData({
            ...loginData,
            [name]: value
        })
    }

    return (
        <div className="login" style={{ "marginTop": "40px" }}>
            <h1>로그인</h1>
            <h3>현재 이 기능은 구현되지 않았습니다</h3>
            <LoginInput
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                loginData={loginData}
            />
        </div>
    )
}

export default Login