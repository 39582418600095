import { useEffect, useState } from "react";
import "./Estimate.css"
import ProductPreview from "./ProductPreview";
import axios from "axios"
import SearchBar from "./SearchBar";
import PageBar from "./PageBar"
import ProductMissing from "../ProductMissing"

const ProductList = ({ partSelect, getProductSelect, searchKeyword, setSearchKeyword, isMobile }) => {
    const [productData, setProductData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        let tempPartSelect = partSelect
        if (tempPartSelect === "front_wheel" || tempPartSelect === "rear_wheel") {
            tempPartSelect = "wheelset"
        }
        axios.get(`${process.env.REACT_APP_API_PARTS}/part`, {
            params: { part: tempPartSelect }
        })
        .then(res => {
            setProductData(res.data)
            setFilteredData(res.data)
        })
    }, [partSelect])

    useEffect(() => {
        if (searchKeyword) {
            let filtered_data = []
            productData.forEach(element => {
                if (element.product_name.includes(searchKeyword)) {
                    filtered_data.push(element)
                }
            });
            setFilteredData(filtered_data)
        } else {
            setFilteredData(productData)
        }
    }, [searchKeyword])

    return (
        <>
            {isMobile
            ? 
            <></>
            :
            <SearchBar
                setSearchKeyword={ setSearchKeyword }
            />
            }
            <div className="product-list">
                {filteredData
                ? 
                filteredData.map((data, i) => (
                    <ProductPreview
                        key={ i }
                        id={ data._id }
                        img_src={ data.img_src }
                        product_name={ data.product_name }
                        simple_explain={ data.simple_explain }
                        product_price={ data.product_price }
                        seatpost_position={ data.seatpost_position }
                        stem_Xposition={ data.stem_Xposition }
                        stem_Yposition={ data.stem_Yposition }
                        getProductSelect={ getProductSelect }
                    />
                ))
                : 
                <ProductMissing/>
                }
            </div>
            {
                // isMobile
                // ? <></>
                // : <PageBar />
            }
        </>
    )
}

export default ProductList