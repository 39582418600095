import React, { useEffect, useState } from "react";
import "./Pages.css"
import ShopSearch from "../components/shop-components/ShopSearch";
import ShopFilter from "../components/shop-components/ShopFilter";
import ShopProductList from "../components/shop-components/ShopProductList";
import { useLocation } from "react-router-dom"

const Shop = ({ isMobile }) => {
    const location = useLocation()
    const [category, setCategory] = useState("")

    useEffect(() => {
        switch (location.pathname) {
            case "/complete":
                setCategory("완성차")
                break
            case "/part":
                setCategory("파츠")
                break
            case "/accessorie":
                setCategory("액세서리")
                break
            case "/etc":
                setCategory("기타")
                break
            default:
                setCategory("")
        }
    }, [location])

    return (
        <div className="shop">
            <h3 className="location">{ category }</h3>
            <ShopSearch/>
            <ShopFilter isMobile={ isMobile }/>
            <ShopProductList isMobile={ isMobile }/>
        </div>
    )
}

export default Shop