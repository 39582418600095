import React from "react";
import "./Login.css"

const LoginInput = ({ handleSubmit, handleChange, loginData }) => {
    return (
        <form className="login-input" onSubmit={ handleSubmit }>
            <input 
                type="text" 
                name="user_id"
                placeholder="아이디" 
                value={ loginData.user_id }
                onChange={ handleChange }
                required
            />
            <input 
                type="password" 
                name="password"
                placeholder="비밀번호" 
                value={ loginData.password }
                onChange={ handleChange }
                required
            />
            <button type="submit">로그인</button>
        </form>
    )
}

export default LoginInput