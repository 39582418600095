import React from "react";
import "./Shop.css"

const ShopFilter = ({ isMobile }) => {
    return (
        <div className="shop-filter">
            {
            isMobile
            ?
            <select>
                <option value="latest">최신순</option>
                <option value="dict">이름순</option>
                <option value="high">높은 가격순</option>
                <option value="low">낮은 가격순</option>
            </select>
            :
            <>
                <span>최신순</span>
                <span>이름순</span>
                <span>높은 가격순</span>
                <span>낮은 가격순</span>
            </>
            }
        </div>
    )
}

export default ShopFilter