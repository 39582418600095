import React from "react";
import "./Components.css"

const ProductMissing = () => {
    return (
        <div className="product-missing">
            상품이 없습니다
        </div>
    )
}

export default ProductMissing