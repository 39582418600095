import React from "react";
import "./Components.css"

const Footer = () => {
    return (
        <div className="footer">
            <a href="https://www.instagram.com/bif_.official/">Contact(insta) : @bif_.official</a>
        </div>
    )
}

export default Footer