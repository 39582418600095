import React from "react";
import "./Estimate.css"

const PurchaseListLayout = ({ part_kind, product_info, postCancelSelect, isDivide }) => {
    const getPartKind = () => {
        if (part_kind === "front_wheel" || part_kind === "rear_wheel") {
            if (!isDivide) {
                return "휠셋"
            } else if (part_kind === "front_wheel") {
                return "프론트휠"
            } else {
                return "리어휠"
            }
        } else if (part_kind === "frame") {
            return "프레임"
        } else if (part_kind === "seatpost") {
            return "싯포스트"
        } else if (part_kind === "saddle") {
            return "안장"
        } else if (part_kind === "stem") {
            return "스템"
        } else if (part_kind === "handlebar") {
            return "핸들바"
        } else if (part_kind === "chainring") {
            return "체인링"
        } else if (part_kind === "crank") {
            return "크랭크"
        } else {
            return "404"
        }
    }

    const getCancelPartKind = () => {
        if (part_kind === "front_wheel" || part_kind === "rear_wheel") {
            if (!isDivide) {
                return "wheelset"
            } else if (part_kind === "front_wheel") {
                return "front_wheel"
            } else {
                return "rear_wheel"
            }
        } else {
            return part_kind
        }
    }

    return (
        <div className="purchase-list-layout">
            <div className="part-kind">
                { getPartKind() }
            </div>
            <div className="part-name">
                { product_info.product_name }
            </div>
            <div className="part-price">
                { product_info.product_price && <>{ product_info.product_price.toLocaleString("ko-KR") } 원</> }
            </div>
            <div className="part-cancel">
                {
                    product_info.product_name
                    ? <button onClick={ () => postCancelSelect(getCancelPartKind()) }>X</button>
                    : <></>
                }
            </div>
        </div>
    )
}

export default PurchaseListLayout